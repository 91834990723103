import axios from 'axios';
import {
  GET_TEAM_PLAYERS,
  GET_TEAM_PLAYERS_SUCCESS,
  GET_TEAM_PLAYERS_FAIL,

  GET_PLAYER_DETAILS,
  GET_PLAYER_DETAILS_SUCCESS,
  GET_PLAYER_DETAILS_FAIL,

  FILTER_TEAM_PLAYERS,
  FILTER_TEAM_PLAYERS_SUCCESS,
  FILTER_TEAM_PLAYERS_FAIL,

  PLAYER_SIMULATION,
  PLAYER_SIMULATION_SUCCESS,
  PLAYER_SIMULATION_FAIL,

  PLAYER_ALL_SIMULATIONS_SUCCESS,

  SIMULATION_BY_EVENT_ID,
  SIMULATION_BY_EVENT_ID_SUCCESS,
  SIMULATION_BY_EVENT_ID_FAIL,

  GET_PLAYER_SUMMARY,
  GET_PLAYER_SUMMARY_SUCCESS,
  GET_PLAYER_SUMMARY_FAIL,

  GET_SIM_ACC_DATA,
  RESET_TEAM_PLAYERS,
  GET_SIM_ACC_DATA_SUCC,
  GET_SIM_ACC_DATA_FAIL,

  GET_PLAYER_ANYLSIS,
  GET_PLAYER_ANYLSIS_SUCC,
  GET_PLAYER_ANYLSIS_FAIL,

  GET_SIMULATION_VIDEOS,
  GET_SIMULATION_VIDEOS_SUCC,
  GET_SIMULATION_VIDEOS_FAIL,

  SET_SIDELINE_URL,

  GET_MPS_VARIUS_TIME,
  GET_MPS_VARIUS_TIME_SUCC,
  GET_MPS_VARIUS_TIME_FAIL,

  GET_TEAM_MPS_VARIUS_TIME_SUCC,

  TRIM_VIDEOS,
  TRIM_VIDEOS_SUCC,
  TRIM_VIDEOS_FAIL,

  RESET_TRIM_STATE,
  SET_VIDEO_FPS,

  RESET_SIDELINE_VIDEO,
  RESET_SIDELINE_VIDEO_SUCC,
  RESET_SIDELINE_VIDEO_FAIL,

  MIRROR_SIDELINE_VIDEO,
  MIRROR_SIDELINE_VIDEO_SUCC,
  MIRROR_SIDELINE_VIDEO_FAIL,
  SET_PLAYER_MPS_VALUES,

  FILTER_PLAYER_SIMULATION,
  FILTER_PLAYER_SIMULATION_SUCCESS,
  FILTER_PLAYER_SIMULATION_FAIL,

  GET_PL_BY_TYPE,
  GET_PL_BY_TYPE_SUCCESS,
  GET_PL_BY_TYPE_FAIL,
  FAMILY_PLAYER_STAFF,
  FAMILY_PLAYER_STAFF_SUCCESS,
  FAMILY_PLAYER_STAFF_FAIL,

  SET_PL_BY_TYPE,

  SET_SELECTED_SPHERE_TYPE,
  MAX_VALUE_OF_BRAIN_EEFECT,
  
  PROCESS_ML_SUBMIT,
  ERROR_MESSAGE_ML_SUBMIT,
  SUCCESS_MESSAGE_ML_SUBMIT,
  RESET_ML_SUBMIT,

  //STRAIN
  GET_PLAYER_ANALYSIS_DATA,
  GET_PLAYER_ANALYSIS_DATA_SUCCESS,
  GET_PLAYER_ANALYSIS_DATA_FAIL,

  //ML
  GET_PLAYER_ML_ANALYSIS_DATA,
  GET_PLAYER_ML_ANALYSIS_DATA_SUCCESS,
  GET_PLAYER_ML_ANALYSIS_DATA_FAIL,

  //PRESSURE
  GET_PLAYER_PRESSURE_ANALYSIS_DATA,
  GET_PLAYER_PRESSURE_ANALYSIS_DATA_SUCCESS,
  GET_PLAYER_PRESSURE_ANALYSIS_DATA_FAIL
} from "./actionTypes"
import { LOGOUT_LOGGEDIN_USER } from "../auth/login/actionTypes";
import { getTeamSimulationsSuccess, getTeamSimulations } from "../team/actions";
import _ from 'lodash'
import config from "../../config/configuration_keys.json"

export const setPlDAta = (body) => ({
  type: SET_PL_BY_TYPE,
  payload: body
})

//Get player lists by type ...
const getPlByType = () => ({
  type: GET_PL_BY_TYPE,
})

const getPlByTypeSuccess = items => ({
  type: GET_PL_BY_TYPE_SUCCESS,
  payload: items,
})

const getPlByTypeFail = error => ({
  type: GET_PL_BY_TYPE_FAIL,
  payload: error,
})

// ml submit
const processMlSubmit = () => ({
  type: PROCESS_ML_SUBMIT,
})

const successMessageMlSubmit = (message) => ({
  type: SUCCESS_MESSAGE_ML_SUBMIT,
  payload: message
})

const errorMessageMlSubmit = message => ({
  type: ERROR_MESSAGE_ML_SUBMIT,
  payload: message,
})

const resetMlSubmit = () => ({
  type: RESET_ML_SUBMIT
})

export const resetMLAct = () => {
  return dispatch => {
    dispatch(resetMlSubmit())
  }
}
export const fetchPlFormlToolkitBytype = (body) => {
  return dispatch => {
    dispatch(getPlByType());

    axios.patch("/player/mltl/players", body)
      .then(function (response) {
        dispatch(getPlByTypeSuccess(response.data.data));

      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getPlByTypeFail(JSON.stringify(error.response.data)));
        }
      });

  }
}

export const processMLSubmit = (body, callback) => {
  return dispatch => {
    dispatch(resetMlSubmit())
    dispatch(processMlSubmit())
    axios.post(config.MLTriggerAPI, body, {
      headers: {
        'x-api-key': config.MLTriggerAPIKey
      }
    }).then((res) => {
      dispatch(successMessageMlSubmit('Machine learning training model successfully submitted.'))
      if(callback){
        console.log("=====in action callback call====")
        callback()
      }
    }).catch((error) => {
      if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
        dispatch(signOutUser())
      } else {
        dispatch(errorMessageMlSubmit(error.message))
      }
    })
  }
}
export const retrainJob = (body) => {
  return dispatch => {
    dispatch(resetMlSubmit())
    dispatch(processMlSubmit())
    axios.post("/sensor/retrain", {entity_id: body})
    .then(async (res) => {
      if(res.data.data.status == 'success'){
        dispatch(successMessageMlSubmit('Machine learning training model successfully submitted.'))
      }
    }).catch((error) => {
      if(error.response && error.response.data && error.response.data.message) dispatch(errorMessageMlSubmit(error.response.data.message))
    })
  }
}

/* Check simulation exist or not */
export const checkSimExistDb = (obj) => {
  return new Promise((resolve, reject) => {
    //axios.get(config.simSensorApiEndpoint + `?sensor_id=${obj.sensor_id}&impact_id=${obj.impact_id}`)
    axios.post(config.simSensorApiEndpoint, {sensorsList: obj})
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      });
  })
}
// fetching player simulations ...
const filterPlayerSim = () => ({
  type: FILTER_PLAYER_SIMULATION,
})

const filterPlayerSimSuccess = items => ({
  type: FILTER_PLAYER_SIMULATION_SUCCESS,
  payload: items,
})

const filterPlayerSimsFail = error => ({
  type: FILTER_PLAYER_SIMULATION_FAIL,
  payload: error,
})

export const filterPlayerSimulations = (body) => {
  return dispatch => {
    dispatch(filterPlayerSim());

    axios.patch("/player/simulations/filter", body)
      .then(function (response) {
        dispatch(filterPlayerSimSuccess(response.data.data));

      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(filterPlayerSimsFail(JSON.stringify(error.response.data)));
        }
      });

  }
}

export const setMpsValues = (data) => ({
  type: SET_PLAYER_MPS_VALUES,
  payload: data
})

export const setSelectedSphereType = (data) => ({
  type: SET_SELECTED_SPHERE_TYPE,
  payload: data
})
export const getMaxValOfBrainEffect = (data) => ({
  type: MAX_VALUE_OF_BRAIN_EEFECT,
  payload: data
})

/* fething undefined players */
export const fetchUndefiendPlayers = (body) => {
  return new Promise((resolve, reject) => {
    axios.get("/player/undefined/list", body)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      });
  })
}

/* fething brain images */
export const mergeVideos = (body) => {
  return new Promise((resolve, reject) => {
    axios.post("/player/video/merge", body)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      });
  })
}

/* fething brain images */
export const fetchPlayerBrainImages = (body) => {
  return new Promise((resolve, reject) => {
    axios.patch("/player/brainModel/images", body)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      });
  })
}


//
export const fetchSimulationLog = (body) => {
  return new Promise((resolve, reject) => {
    axios.patch("/player/simulation/logs", body)
      .then(function (response) {
        resolve(response.data.data);
      })
      .catch(function (error) {
        reject(JSON.stringify(error.response.data));
      });
  })
}


//FLIP SIDELINE VIDEO
const flipSidelineVideo = () => ({
  type: MIRROR_SIDELINE_VIDEO,
})

const flipSidelineVideoSuccess = items => ({
  type: MIRROR_SIDELINE_VIDEO_SUCC,
  payload: items,
})

const flipSidelineVideoFail = error => ({
  type: MIRROR_SIDELINE_VIDEO_FAIL,
  payload: error,
})

export const mirrorSidelineVideo = (body) => {
  return dispatch => {
    dispatch(flipSidelineVideo());
    axios.post("/player/flip/video", body)
      .then(function (response) {
        dispatch(flipSidelineVideoSuccess(response.data.data));
      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(flipSidelineVideoFail(JSON.stringify(error.response.data)));
        }
      });
  }
}
// ...


//RESET VIDEO TO ORIGINAL
const resetSidelineVideo = () => ({
  type: RESET_SIDELINE_VIDEO,
})

const resetSidelineVideoSuccess = items => ({
  type: RESET_SIDELINE_VIDEO_SUCC,
  payload: items,
})

const resetSidelineVideoFail = error => ({
  type: RESET_SIDELINE_VIDEO_FAIL,
  payload: error,
})

export const resetSidelineVideoToOriginal = (body) => {
  return dispatch => {
    dispatch(resetSidelineVideo());
    axios.patch("/player/reset/video", body)
      .then(function (response) {
        dispatch(resetSidelineVideoSuccess(response.data.data));
      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(resetSidelineVideoFail(JSON.stringify(error.response.data)));
        }
      });
  }
}
// ...

// TRIM VIDEO ACTION ...

export const resetTrimStates = () => ({
  type: RESET_TRIM_STATE,
})


export const setVideoFps = (data) => ({
  type: SET_VIDEO_FPS,
  payload: data
})


const trimVideo = () => ({
  type: TRIM_VIDEOS,
})

const trimVideoSuccess = items => ({
  type: TRIM_VIDEOS_SUCC,
  payload: items,
})

const trimVideoFail = error => ({
  type: TRIM_VIDEOS_FAIL,
  payload: error,
})

export const trimSidelineVideo = (body) => {
  return dispatch => {
    dispatch(trimVideo());
    axios.post("/player/trim/video", body)
      .then(function (response) {
        dispatch(trimVideoSuccess(response.data.data));
      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(trimVideoFail(JSON.stringify(error.response.data)));
        }
      });
  }
}
// ...

// fetching data of brain mps loading over time ...
const getBrainMpsTime = () => ({
  type: GET_MPS_VARIUS_TIME,
})

const getBrainMpsTimeSuccess = items => ({
  type: GET_MPS_VARIUS_TIME_SUCC,
  payload: items,
})

const getBrainMpsTimeFail = error => ({
  type: GET_MPS_VARIUS_TIME_FAIL,
  payload: error,
})




const getTeamBrainMpsTimeSuccess = items => ({
  type: GET_TEAM_MPS_VARIUS_TIME_SUCC,
  payload: items,
})


export const fetchTeamPlayersBrainMPSLoadinByTime = (body) => {
  return dispatch => {
    const { teamPlayers } = body;
    var data = [];
    var fetches = [];
    for (let i = 0; i < teamPlayers.length; i++) {
      if (teamPlayers[i].users.length > 0) {

        const user = { user_id: teamPlayers[i].users[0]._id };

        var fetche = axios.patch("/player/mps/overTime", user)
          .then(function (response) {
            data.push(response.data.data);
          })
          .catch(function (error) {
            if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
              dispatch(signOutUser())
            } else {
              dispatch(getBrainMpsTimeFail(JSON.stringify(error.response.data)));
            }
          });

        fetches.push(fetche);

      }
    }

    Promise.all(fetches).then(function () {
      dispatch(getTeamBrainMpsTimeSuccess(data));
    });

  }


}

export const fetchPlayerBrainMPSLoadinByTime = (body) => {
  return dispatch => {
    dispatch(getBrainMpsTime());
    axios.patch("/player/mps/overTime", body)
      .then(function (response) {
        dispatch(getBrainMpsTimeSuccess(response.data.data));
      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getBrainMpsTimeFail(JSON.stringify(error.response.data)));
        }
      });
  }
}
// ...

export const removeSidelineVideo = (body) => {
  return new Promise((resolve, reject) => {
    axios.post("/player/sideline/removeVideos", body)
      .then(function (response) {
        resolve(response.data.data);
      })
      .catch(function (error) {
        reject(JSON.stringify(error.response.data));
      });
  })
}

export const uploadSidelineVideo = (data, options) => {
  return new Promise((resolve, reject) => {
    axios.post("/player/sideline/videos", data, options)
      .then(function (response) {
        resolve(response.data.data);
      })
      .catch(function (error) {
        reject(JSON.stringify(error.response.data));
      });
  })
}

export const setSidelineVideoUrl = (url) => ({
  type: SET_SIDELINE_URL,
  payload: url,

})




// fetching player anylsis data ...
const getSimVideos = () => ({
  type: GET_SIMULATION_VIDEOS,
})

const getSimVideosSuccess = items => ({
  type: GET_SIMULATION_VIDEOS_SUCC,
  payload: items,
})

const getSimVideosFail = error => ({
  type: GET_SIMULATION_VIDEOS_FAIL,
  payload: error,
})

export const fetchSiulationVideos = (body) => {
  return dispatch => {
    dispatch(getSimVideos());
    axios.patch("/player/simulation/videos", body)
      .then(function (response) {
        dispatch(getSimVideosSuccess(response.data.data));
      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getSimVideosFail(JSON.stringify(error.response.data)));
        }
      });
  }
}

/* Get single event output */
export const fetchSingleEventOutput = (body) => {
  return new Promise((resolve, reject) => {
    axios.patch("/player/single/output", body)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      });
  })
}

/* Get single event output */
export const fetchMPSdata = (body) => {
  return new Promise((resolve, reject) => {
    axios.patch("/player/fetch/mpsData", body)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      });
  })
}


// fetching player anylsis data ...
const getPlayerAnylsisData = () => ({
  type: GET_PLAYER_ANYLSIS,
})

const getPlayerAnylsisDataSuccess = items => ({
  type: GET_PLAYER_ANYLSIS_SUCC,
  payload: items,
})

const getPlayerAnylsisDataFail = error => ({
  type: GET_PLAYER_ANYLSIS_FAIL,
  payload: error,
})

export const fetchPlayerAnylsisData = (body) => {
  return dispatch => {
    dispatch(getPlayerAnylsisData());
    axios.patch("/player/anylsisData", body)
      .then(function (response) {
        dispatch(getPlayerAnylsisDataSuccess(response.data.data));
      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getPlayerAnylsisDataFail(JSON.stringify(error.response.data)));
        }
      });
  }
}

// fetchin evnet simulation acceleration data ...
const getSimAccData = () => ({
  type: GET_SIM_ACC_DATA,
})

const getSimAccDataSuccess = items => ({
  type: GET_SIM_ACC_DATA_SUCC,
  payload: items,
})

const getSimAccDataFail = error => ({
  type: GET_SIM_ACC_DATA_FAIL,
  payload: error,
})

export const fetchEventSimAccData = (body) => {
  return dispatch => {
    dispatch(getSimAccData());
    axios.patch("/player/simulation/ByEvent", body)
      .then(function (response) {
        dispatch(getSimAccDataSuccess(response.data.data));

      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getSimAccDataFail(JSON.stringify(error.response.data)));
        }
      });

  }
}

// fetching player simulations BY EVENT ID ...
const getPlayerSummary = () => ({
  type: GET_PLAYER_SUMMARY,
})

const getPlayerSummarySuccess = items => ({
  type: GET_PLAYER_SUMMARY_SUCCESS,
  payload: items,
})

const getPlayerSummaryFail = error => ({
  type: GET_PLAYER_SUMMARY_FAIL,
  payload: error,
})

export const fetchPlayerSummaryData = (body) => {
  return dispatch => {
    dispatch(getPlayerSummary());
    axios.patch("/player/summary/ById", body)
      .then(function (response) {
        dispatch(getPlayerSummarySuccess(response.data.data));

      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getPlayerSummaryFail(JSON.stringify(error.response)));
        }
      });

  }
}

// fetching player simulations BY EVENT ID ...
const getSimByEvenetId = () => ({
  type: SIMULATION_BY_EVENT_ID,
})

const getSimByEvenetIdSuccess = items => ({
  type: SIMULATION_BY_EVENT_ID_SUCCESS,
  payload: items,
})

const getSimByEvenetIdFail = error => ({
  type: SIMULATION_BY_EVENT_ID_FAIL,
  payload: error,
})

export const fetchSimulationByEventId = (body) => {
  return dispatch => {
    dispatch(getSimByEvenetId());

    axios.patch("/player/simulation/ByEventId", body)
      .then(function (response) {
        dispatch(getSimByEvenetIdSuccess(response.data.data));
      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getSimByEvenetIdFail(JSON.stringify(error.response.data)));
        }
      });

  }
}

// fetching player simulations ...
const getPlayerSim = () => ({
  type: PLAYER_SIMULATION,
})

const getPlayerSimSuccess = items => ({
  type: PLAYER_SIMULATION_SUCCESS,
  payload: items,
})

const getPlayerSimsFail = error => ({
  type: PLAYER_SIMULATION_FAIL,
  payload: error,
})

export const fetchPlayerSimulations = (body) => {
  return dispatch => {
    dispatch(getPlayerSim());
    axios.patch("/player/simulations", body)
      .then(function (response) {
        dispatch(getPlayerSimSuccess(response.data.data));

      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getPlayerSimsFail(JSON.stringify(error.response.data)));
        }
      });

  }
}



const getPlayerAllSimSuccess = items => ({
  type: PLAYER_ALL_SIMULATIONS_SUCCESS,
  payload: items,
})

export const fetchAllPlayerSimulations = (body) => {
  return dispatch => {
    //dispatch(getPlayerSim());

    //Hadrdcoded max simulations, TODO: make itemPerPage not required param on the backend
    body.itemPerPage = 1000;
    axios.patch("/player/simulations", body)
      .then(function (response) {
        dispatch(getPlayerAllSimSuccess(response.data.data));

      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getPlayerSimsFail(JSON.stringify(error.response.data)));
        }
      });

  }
}


// GET ORG INS. LIST
const getTeamPlayers = (ins_id) => ({
  type: GET_TEAM_PLAYERS,
  payload: ins_id
})

const getTeamPlayersSuccess = items => ({
  type: GET_TEAM_PLAYERS_SUCCESS,
  payload: items,
})

const getTeamPlayersFail = error => ({
  type: GET_TEAM_PLAYERS_FAIL,
  payload: error,
})

export const fetchTeamPlayers = (body) => {
  return dispatch => {
    dispatch(getTeamPlayers());
    dispatch(getTeamSimulations());
    axios.patch("/player/fetchListByTeam", body)
      .then((res) => res.data.data)
      .then((response) => {
        setTimeout(() => dispatch(getTeamPlayersSuccess(response)) , 300)
      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getTeamPlayersFail(JSON.stringify(error.response.data)));
        }
      });

  }
}

export const resetTeamPlayers = () => ({
  type: RESET_TEAM_PLAYERS
})

export const fetchTeamPlayersOptim = (body) => {
  return dispatch => {
    dispatch(resetTeamPlayers())
    dispatch(getTeamPlayers());
    // dispatch(getTeamSimulations());
    axios.patch("/player/fetchListByTeamOptim", body)
      .then((res) => res.data.data)
      .then((response) => {
        /* setTimeout(() =>
          dispatch(getTeamPlayersSuccess(response.data.data))
          , 300) */
        dispatch(getTeamPlayersSuccess(response))
        /* const { players } = response
        const teamSimulation = _.flattenDeep(players.map((innerEl) => {
          return innerEl.sensor_details.map((el) => {
            return {
              ...el,
              users: innerEl.users
            }
          })
        })).filter((el) => el)
        dispatch(getTeamSimulationsSuccess(teamSimulation)) */
      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getTeamPlayersFail(JSON.stringify(error.response.data)));
        }
      });

  }
}



// GET FILTERED PLAYERS
const filterTeamPlayers = (ins_id) => ({
  type: FILTER_TEAM_PLAYERS,
  payload: ins_id
})

const filterTeamPlayersSuccess = items => ({
  type: FILTER_TEAM_PLAYERS_SUCCESS,
  payload: items,
})

const filterTeamPlayersFail = error => ({
  type: FILTER_TEAM_PLAYERS_FAIL,
  payload: error,
})

export const filterTeamAllPlayers = (body) => {
  return dispatch => {
    dispatch(filterTeamPlayers());

    axios.patch("/player/filterList", body)
      .then(function (response) {
        dispatch(filterTeamPlayersSuccess(response.data.data));

      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(filterTeamPlayersFail(JSON.stringify(error.response.data)));
        }
      });

  }
}

/**
 * Fetching deatils of single players
 * @param player_id
 * @returns details
 */
const getPlayerDetails = () => ({
  type: GET_PLAYER_DETAILS,
})

const getPlayerDetailsSuccess = items => ({
  type: GET_PLAYER_DETAILS_SUCCESS,
  payload: items,
})

const getPlayerDetailsFail = error => ({
  type: GET_PLAYER_DETAILS_FAIL,
  payload: error,
})

export const fetchPlayerDetails = (body) => {
  return dispatch => {
    dispatch(getPlayerDetails());
    axios.patch("/player/fetchDetails", body)
      .then(function (response) {
        dispatch(getPlayerDetailsSuccess(response.data.data));
      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getPlayerDetailsFail(JSON.stringify(error.response ? error.response.data.message : 'Failed to load data.')));
        }
      });
  }
}

/**
 * Get count...
 */
export const getSimulationCountsByType = (body) => {
  return new Promise((resolve, reject) => {
    axios.patch("/player/getCountByType", body)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      });
  })
}

export const createLogsforEmail = (body) => {
  return new Promise((resolve, reject) => {
    axios.post("/player/createLogsforEmail", body)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
      });
  })
}

// Signout user if token invalid
const signOutUser = () => ({
  type: LOGOUT_LOGGEDIN_USER
})
export const createPDFandemailforevent = (body) => {
  return new Promise((resolve, reject) => {
    axios.post("/player/createPDFandemailforevent", body)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        alert('Failed to upload! Somthing went wrong, please try again.')
      });
  })
}
export const createPDFandemailforteam = (body) => {
  return new Promise((resolve, reject) => {
    axios.post("/player/createPDFandemailforteam", body)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        alert('Failed to upload! Somthing went wrong, please try again.')
      });
  })
}
const familyPlayerStaff = (state) => ({
  type: FAMILY_PLAYER_STAFF,
})

const familyPlayerStaffSuccess = (items) => ({
  type: FAMILY_PLAYER_STAFF_SUCCESS,
  payload: items
})

const familyPlayerStaffFail = (err) => ({
  type: FAMILY_PLAYER_STAFF_FAIL,
  payload: err
})
 export const getfamilyPlayerStaffList = (body) => {
   return dispatch => {
    dispatch(familyPlayerStaff());

    axios.patch("/player/getfamilyplayerStaffList", body)
      .then(function (response) {
        dispatch(familyPlayerStaffSuccess(response.data.data));
      })
      .catch(function (error) {
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(familyPlayerStaffFail(JSON.stringify(error.response.data.message)));
        }
      });
   }
 }

// new analysis data for strain
const getPlayerAnalysisData = () => ({
  type: GET_PLAYER_ANALYSIS_DATA
})

const getPlayerAnalysisDataSuccess = (payload) => ({
  type: GET_PLAYER_ANALYSIS_DATA_SUCCESS,
  payload
})

const getPlayerAnalysisDataFail = (payload) => ({
  type: GET_PLAYER_ANALYSIS_DATA_FAIL,
  payload
})

export const fetchPlayerOptimizeAnalysis = (data) => {
  let id = data.id;
  let pressure_dashboard = data.pressure_dashboard?data.pressure_dashboard:false;
  return dispatch => {
    dispatch(getPlayerAnalysisData())
    axios.get(`/player/summary-preview/${id}/${pressure_dashboard}`)
    .then((response) => response.data.data)
    .then((response) => {
      dispatch(getPlayerAnalysisDataSuccess(response));
    })
    .catch(function (error) {
      if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
        dispatch(signOutUser())
      } else {
        dispatch(getPlayerAnalysisDataFail(JSON.stringify(error.response.data)));
      }
    });
  }
}

// new analysis data for ml
const getPlayerMlAnalysisData = () => ({
  type: GET_PLAYER_ML_ANALYSIS_DATA
})

const getPlayerMlAnalysisDataSuccess = (payload) => ({
  type: GET_PLAYER_ML_ANALYSIS_DATA_SUCCESS,
  payload
})

const getPlayerMlAnalysisDataFail = (payload) => ({
  type: GET_PLAYER_ML_ANALYSIS_DATA_FAIL,
  payload
})

export const fetchPlayerOptimizeMlAnalysis = (data) => {
  let id = data.id;
  let pressure_dashboard = data.pressure_dashboard?data.pressure_dashboard:false;
  return dispatch => {
    dispatch(getPlayerMlAnalysisData())
    axios.get(`/player/Mlsummary-preview/${id}/${pressure_dashboard}`)
    .then((response) => response.data.data)
    .then((response) => {
      dispatch(getPlayerMlAnalysisDataSuccess(response));
    })
    .catch(function (error) {
      if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
        dispatch(signOutUser())
      } else {
        dispatch(getPlayerMlAnalysisDataFail(JSON.stringify(error.response.data)));
      }
    });
  }
}
//
// new analysis data for pressure
const getPlayerPressureAnalysisData = () => ({
  type: GET_PLAYER_PRESSURE_ANALYSIS_DATA
})

const getPlayerPressureAnalysisDataSuccess = (payload) => ({
  type: GET_PLAYER_PRESSURE_ANALYSIS_DATA_SUCCESS,
  payload
})

const getPlayerPressureAnalysisDataFail = (payload) => ({
  type: GET_PLAYER_PRESSURE_ANALYSIS_DATA_FAIL,
  payload
})

export const fetchPlayerOptimizePressureAnalysis = (data) => {
  let id = data.id;
  let pressure_dashboard = data.pressure_dashboard?data.pressure_dashboard:false;
  return dispatch => {
    dispatch(getPlayerPressureAnalysisData())
    axios.get(`/player/pressuresummary-preview/${id}/${pressure_dashboard}`)
    .then((response) => response.data.data)
    .then((response) => {
      dispatch(getPlayerPressureAnalysisDataSuccess(response));
    })
    .catch(function (error) {
      if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
        dispatch(signOutUser())
      } else {
        dispatch(getPlayerPressureAnalysisDataFail(JSON.stringify(error.response.data)));
      }
    });
  }
}
